<template>
  <div>
    <a-modal
      :visible="visible"
      destroyOnClose
      title="属性选择"
      width="60%"
      centered
      okText="确认"
      cancelText="取消"
      @ok="() => onSubmit()"
      @cancel="() => hideDialog()"
    >
      <div class="property-operations">
        <div class="data-filter">
          <a-input
            v-model="searchKeyword"
            class="search-keyword"
            clearable
            placeholder="请输入属性标识符或属性名称"
            @keyup.enter.native="doSearch"
          ></a-input>
          <a-button class="search-btn" type="primary" @click="doSearch()">搜索</a-button>
        </div>
      </div>
      <a-table
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, type: selectType }"
        size="middle"
        :data-source="tableDate"
        class="property-table"
        rowKey="id"
      >
        <a-table-column key="identifier" title="属性标识符" data-index="identifier" align="center">
          <template slot-scope="scope">{{ scope }}</template>
        </a-table-column>
        <a-table-column key="encode" title="属性定义唯一标识" data-index="encode" align="center">
          <template slot-scope="scope">{{ scope }}</template>
        </a-table-column>
        <a-table-column key="name" title="属性名称" data-index="name" align="center">
          <template slot-scope="scope">{{ scope }}</template>
        </a-table-column>
        <a-table-column key="propertyType" title="属性类型" data-index="propertyType" align="center">
          <template slot-scope="scope">{{
            scope === $constRepository.property.FILTER_OPTION_PROPERTY_NODE_FATHER.value ? '父节点' : '子节点'
          }}</template>
        </a-table-column>
        <!-- <a-table-column key="createdTime" title="创建时间" data-index="createdTime" align="center">
          <template slot-scope="scope">{{   scope | parseTime | orNA }}</template>
        </a-table-column> -->
        <a-table-column
          key="lastModificationTime"
          title="最后更新时间"
          data-index="lastModificationTime"
          align="center"
        >
          <template slot-scope="scope">{{ scope | parseTime | orNA }}</template>
        </a-table-column>
        <a-table-column key="action" title="查看" align="center">
          <template slot-scope="scope">
            <PropertyPopover :id="scope.id" />
          </template>
        </a-table-column>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment';
import lodash from 'lodash';
// import LoadedDevicePropertyCard from '../../loaded-device/components/loadedDevicePropertyCard';
import PropertyPopover from './PropertyPopover';

export default {
  name: 'PropertyFormModal',
  components: { PropertyPopover },
  data() {
    return {
      visible: false,
      selectType: 'radio',
      resultCallBack: {
        onSubmit: Function,
      },
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
      searchKeyword: '',
      selectedRowKeys: [],
      tableDate: [],
      moment,
    };
  },
  methods: {
    cancel() {
      this.$router.back();
    },
    hideDialog() {
      this.visible = false;
    },
    async listProperties() {
      const params = {
        propertyType: this.$constRepository.property.propertyType.root,
        searchKey: this.searchKeyword,
        unpaged: true,
      };
      const response = await this.$apiManager.property.getProperties(params);
      this.tableDate = response.data.elements;
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    async showCreateDialog(callback) {
      await this.listProperties();
      this.resultCallBack.onSubmit = callback;
      this.visible = true;
    },
    async showUpdateDialog(pararms, callback) {
      await this.listProperties();
      this.productCategoryForm = pararms;
      this.resultCallBack.onSubmit = callback;
      this.visible = true;
    },
    async onSubmit() {
      // const target = this.tableDate.find((property) => property.id === this.selectedRowKeys[0]);
      if (!this.selectedRowKeys?.length) {
        this.$message.warn('请选择属性');
        return;
      }
      const params = {
        id: this.selectedRowKeys[0],
      };
      const response = await this.$apiManager.property.getPropertiesById(params);
      this.resultCallBack.onSubmit(lodash.cloneDeep(response.data || {}));
      this.hideDialog();
    },

    doSearch() {
      this.pageInfo.page = 1;
      this.listProperties();
    },
  },
};
</script>
<style lang="less" scoped>
.popover-content {
  width: 50vw;
}
.content-layout {
  height: auto;
}
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
.property-operations {
  display: flex;
  justify-content: space-between;
  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tip {
      color: #4c4c4c;
    }
    .date-picker-style {
      margin: 0 10px;
    }
    .search-options {
      width: 100px;
    }
    .search-keyword {
      width: 200px;
    }
    .search-btn {
      margin: 10px 0px;
      margin-left: 3%;
    }
  }
  .property-table {
    margin-top: 2%;
  }
}
.pagination {
  margin-top: 3%;
}
</style>
